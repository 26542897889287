import React from "react";

export const HeaderMobileLogo = () => {
  return (
    <svg
      width="115"
      height="32"
      viewBox="0 0 115 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="28.6177" width="0.91081" height="2.2941" fill="#3F4D63" />
      <rect
        x="5.64722"
        y="27.7353"
        width="0.882348"
        height="5.64702"
        transform="rotate(90 5.64722 27.7353)"
        fill="#3F4D63"
      />
      <rect
        x="5.64722"
        y="30.9118"
        width="0.882348"
        height="5.64702"
        transform="rotate(90 5.64722 30.9118)"
        fill="#3F4D63"
      />
      <rect
        x="7.05859"
        y="27.7353"
        width="0.882347"
        height="4.0588"
        fill="#3F4D63"
      />
      <rect
        x="11.8235"
        y="30.2058"
        width="0.882347"
        height="1.58823"
        fill="#3F4D63"
      />
      <rect
        x="11.8235"
        y="27.7354"
        width="0.882347"
        height="1.58823"
        fill="#3F4D63"
      />
      <rect
        x="11.8235"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 11.8235 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="11.8235"
        y="29.3236"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 11.8235 29.3236)"
        fill="#3F4D63"
      />
      <rect
        x="14.1174"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="#3F4D63"
      />
      <rect
        x="18.8823"
        y="27.7354"
        width="0.882347"
        height="4.0588"
        fill="#3F4D63"
      />
      <rect
        x="18.8823"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 18.8823 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="18.8823"
        y="29.3236"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 18.8823 29.3236)"
        fill="#3F4D63"
      />
      <rect
        x="21.1763"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="#3F4D63"
      />
      <rect
        x="25.9409"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 25.9409 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="25.4116"
        y="29.3236"
        width="0.882348"
        height="4.23527"
        transform="rotate(90 25.4116 29.3236)"
        fill="#3F4D63"
      />
      <rect
        x="29.2942"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="#3F4D63"
      />
      <rect
        x="32.1177"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 32.1177 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="35.8528"
        y="28.6357"
        width="0.900245"
        height="3.24088"
        fill="#3F4D63"
      />
      <rect
        x="39.2737"
        y="27.7354"
        width="0.900245"
        height="3.42093"
        transform="rotate(90 39.2737 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="42.6948"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        transform="rotate(90 42.6948 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="40.1738"
        y="31.8766"
        width="0.900245"
        height="3.24088"
        transform="rotate(-180 40.1738 31.8766)"
        fill="#3F4D63"
      />
      <rect
        x="43.595"
        y="31.8766"
        width="0.900245"
        height="3.24088"
        transform="rotate(-180 43.595 31.8766)"
        fill="#3F4D63"
      />
      <rect
        x="71.1421"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="#3F4D63"
      />
      <rect
        x="76.0037"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="76.0037"
        y="27.7354"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="76.0037"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 76.0037 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="76.0037"
        y="29.3559"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 76.0037 29.3559)"
        fill="#3F4D63"
      />
      <rect
        x="45.0356"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="#3F4D63"
      />
      <rect
        x="49.8967"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="49.8967"
        y="29.0883"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="49.8967"
        y="27.7354"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="49.8967"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 49.8967 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="49.8967"
        y="29.3559"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 49.8967 29.3559)"
        fill="#3F4D63"
      />
      <rect
        x="64.8408"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="#3F4D63"
      />
      <rect
        x="69.7021"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="69.7021"
        y="30.9764"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 30.9764)"
        fill="#3F4D63"
      />
      <rect
        x="69.7021"
        y="29.3559"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 29.3559)"
        fill="#3F4D63"
      />
      <rect
        x="52.2375"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        fill="#3F4D63"
      />
      <rect
        x="56.1985"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="56.1985"
        y="30.9765"
        width="0.900245"
        height="3.96108"
        transform="rotate(90 56.1985 30.9765)"
        fill="#3F4D63"
      />
      <rect
        x="57.0986"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 57.0986 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="57.0986"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 57.0986 29.356)"
        fill="#3F4D63"
      />
      <rect
        x="78.3445"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        fill="#3F4D63"
      />
      <rect
        x="82.3057"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="#3F4D63"
      />
      <rect
        x="82.3057"
        y="30.9764"
        width="0.900245"
        height="3.96108"
        transform="rotate(90 82.3057 30.9764)"
        fill="#3F4D63"
      />
      <rect
        x="83.2058"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 83.2058 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="83.2058"
        y="29.3559"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 83.2058 29.3559)"
        fill="#3F4D63"
      />
      <rect
        x="60.5195"
        y="28.6357"
        width="0.900245"
        height="3.24088"
        fill="#3F4D63"
      />
      <rect
        x="63.4001"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 63.4001 27.7354)"
        fill="#3F4D63"
      />
      <rect
        x="54.1177"
        y="11.5"
        width="3.38235"
        height="12.1764"
        fill="black"
      />
      <rect
        x="66.9705"
        y="8.11765"
        width="3.38235"
        height="12.8529"
        transform="rotate(90 66.9705 8.11765)"
        fill="black"
      />
      <rect
        x="79.8235"
        y="8.11765"
        width="3.38235"
        height="9.47057"
        transform="rotate(90 79.8235 8.11765)"
        fill="black"
      />
      <rect
        x="70.3528"
        y="23.6765"
        width="3.38235"
        height="12.1764"
        transform="rotate(-180 70.3528 23.6765)"
        fill="black"
      />
      <rect
        x="83.2058"
        y="23.6765"
        width="3.38235"
        height="12.1764"
        transform="rotate(-180 83.2058 23.6765)"
        fill="black"
      />
      <rect y="4.73528" width="3.38235" height="18.9411" fill="black" />
      <rect
        x="21.647"
        y="8.11763"
        width="3.38235"
        height="21.647"
        transform="rotate(90 21.647 8.11763)"
        fill="black"
      />
      <rect
        x="21.647"
        y="23.6765"
        width="3.38235"
        height="13.5294"
        transform="rotate(-180 21.647 23.6765)"
        fill="black"
      />
      <path
        d="M91.6477 13.0318C91.6477 13.0318 105.416 12.9843 105.501 13.0318C105.586 13.0793 103.853 8.35482 98.5803 8.30734C93.296 8.24799 91.6477 13.0318 91.6477 13.0318Z"
        fill="#FF4F00"
      />
      <path
        d="M107.283 16.5693C107.283 16.5693 91.3084 16.6406 91.2235 16.5693C91.1387 16.4981 93.1385 22.8251 99.2591 22.8963C105.38 22.9675 107.283 16.5693 107.283 16.5693Z"
        fill="#FF4F00"
      />
      <path
        d="M105.67 19.5012L109.209 22.2552L111.027 16.5573H107.21"
        fill="#FF4F00"
      />
      <path
        d="M90.7021 7.17961L88.6174 3.96272C88.6174 3.96272 99.5619 -5.20145 109.937 4.34252C103.804 3.47597 98.9801 3.72529 96.6046 4.30695C94.12 4.92422 92.3141 6.0282 92.3141 6.0282C91.599 6.45554 91.0536 6.88285 90.7021 7.17961Z"
        fill="#FF4F00"
      />
      <path
        d="M108.858 11.0019C108.337 9.92172 106.846 6.81156 103.428 5.304C101.21 4.31874 99.0772 4.40187 98.0106 4.44935C94.8958 4.59179 93.1384 5.71947 93.0414 5.52954C92.8839 5.20903 97.95 1.71911 103.307 1.70724C105.743 1.69537 107.234 2.40762 107.622 2.60942C107.888 2.73999 108.725 3.17922 109.925 4.33067C111.015 5.37528 114.554 8.85338 114.954 14.575C114.979 14.848 114.979 15.0617 114.991 15.2041C115.027 16.2725 115.1 20.8189 111.973 25.0211C109.137 28.8435 104.665 30.909 104.156 30.351C103.671 29.8169 107.428 27.4309 109.197 22.2553C109.646 20.9614 111.258 16.0113 108.858 11.0019Z"
        fill="#FF4F00"
      />
      <path
        d="M104.022 30.3036L101.901 27.0628C102.75 26.9323 103.404 26.7305 103.828 26.5881C104.531 26.3506 106.277 25.769 107.525 24.5582C109.404 22.742 109.537 20.1067 109.719 20.1541C109.888 20.2016 109.391 22.3384 109.694 22.4215C109.828 22.4689 110.082 22.0653 110.325 21.638C111.185 22.3977 111.428 23.0862 111.5 23.5492C111.852 25.8283 108.979 28.701 104.156 30.3391"
        fill="#FF4F00"
      />
      <path
        d="M104.677 19.5369L109.525 23.0981L111.67 20.8901L105.028 18.0174L104.677 19.4657"
        fill="#FF4F00"
      />
      <rect x="27.0586" y="11.5" width="3.49145" height="8.7941" fill="black" />
      <rect
        x="48.7056"
        y="8.11765"
        width="3.38235"
        height="21.647"
        transform="rotate(90 48.7056 8.11765)"
        fill="black"
      />
      <rect
        x="48.7056"
        y="20.2942"
        width="3.38235"
        height="21.647"
        transform="rotate(90 48.7056 20.2942)"
        fill="black"
      />
      <rect
        x="48.7056"
        y="20.2942"
        width="3.49146"
        height="8.7941"
        transform="rotate(-180 48.7056 20.2942)"
        fill="black"
      />
    </svg>
  );
};
