import React from "react";

export const Instagram = ({ fill }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 16C20 16.7911 19.7654 17.5645 19.3259 18.2223C18.8864 18.8801 18.2616 19.3928 17.5307 19.6955C16.7998 19.9983 15.9956 20.0775 15.2196 19.9231C14.4437 19.7688 13.731 19.3878 13.1716 18.8284C12.6122 18.269 12.2312 17.5563 12.0769 16.7804C11.9225 16.0044 12.0017 15.2002 12.3045 14.4693C12.6072 13.7384 13.1199 13.1136 13.7777 12.6741C14.4355 12.2346 15.2089 12 16 12C17.0599 12.0033 18.0754 12.4258 18.8248 13.1752C19.5742 13.9246 19.9967 14.9401 20 16V16ZM28.5 10.5V21.5C28.5 23.3565 27.7625 25.137 26.4497 26.4497C25.137 27.7625 23.3565 28.5 21.5 28.5H10.5C8.64348 28.5 6.86301 27.7625 5.55025 26.4497C4.2375 25.137 3.5 23.3565 3.5 21.5V10.5C3.5 8.64348 4.2375 6.86301 5.55025 5.55025C6.86301 4.2375 8.64348 3.5 10.5 3.5H21.5C23.3565 3.5 25.137 4.2375 26.4497 5.55025C27.7625 6.86301 28.5 8.64348 28.5 10.5V10.5ZM22 16C22 14.8133 21.6481 13.6533 20.9888 12.6666C20.3295 11.6799 19.3925 10.9108 18.2961 10.4567C17.1997 10.0026 15.9933 9.88378 14.8295 10.1153C13.6656 10.3468 12.5965 10.9182 11.7574 11.7574C10.9182 12.5965 10.3468 13.6656 10.1153 14.8295C9.88378 15.9933 10.0026 17.1997 10.4567 18.2961C10.9108 19.3925 11.6799 20.3295 12.6666 20.9888C13.6533 21.6481 14.8133 22 16 22C17.5913 22 19.1174 21.3679 20.2426 20.2426C21.3679 19.1174 22 17.5913 22 16ZM24 9.5C24 9.20333 23.912 8.91332 23.7472 8.66665C23.5824 8.41997 23.3481 8.22771 23.074 8.11418C22.7999 8.00065 22.4983 7.97094 22.2074 8.02882C21.9164 8.0867 21.6491 8.22956 21.4393 8.43934C21.2296 8.64912 21.0867 8.91639 21.0288 9.20736C20.9709 9.49834 21.0007 9.79994 21.1142 10.074C21.2277 10.3481 21.42 10.5824 21.6666 10.7472C21.9133 10.912 22.2033 11 22.5 11C22.8978 11 23.2794 10.842 23.5607 10.5607C23.842 10.2794 24 9.89782 24 9.5Z"
        fill={fill}
      />
    </svg>
  );
};
