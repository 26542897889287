import React from "react";
import {
  Hero,
  OurStory,
  WelcomeToUs,
  WhyChooseUsList,
  OurProjects,
} from "../components";

export const HomePage = () => {
  return (
    <div style={{ marginBottom: "100px" }}>
      <Hero />
      <WelcomeToUs />
      <OurProjects />
      <OurStory />
      <WhyChooseUsList />
    </div>
  );
};
