import React, { useEffect, useState } from "react";
import { RouteNames } from "../../router";
import { Button } from "../../ui";
import { ImageModal, VideoPlayer } from "..";
import { SocialIconsList } from "../SocialIconsList";
import { useWidth } from "../../hooks/UseWidth";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import style from "./AboutProject.module.css";

export const AboutProject = ({ card }) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const windowWidth = useWidth();
  const storage = getStorage();
  const image = ref(storage, card.video);

  useEffect(() => {
    if (!card.video) return;
    getDownloadURL(image).then((url) => {
      setVideoSrc(url);
    });
  }, [videoSrc]);

  return (
    <div className={style.aboutProjectContainer}>
      <div className={style.aboutProjectInfo}>
        {windowWidth > 800 && (
          <Button text={"Back to portfolio"} path={RouteNames.PORTFOLIO} />
        )}
        <h1 className={style.aboutProjectTitle}>{card.title}</h1>
        <p className={style.aboutProjectText}>{card.text}</p>
        <div className={style.aboutProjectLine} />
        {windowWidth > 800 && <SocialIconsList fill={"#3F4D63"} />}
      </div>
      <div className={style.aboutProjectImageContainer}>
        <ImageModal
          img={card.img}
          alt={card.alt}
          title={card.title}
          text={card.text}
        />
        <div className={style.aboutProjectImagesList}>
          {card.collection &&
            card.collection.map((item) => (
              <ImageModal
                key={item.id}
                img={item.img}
                alt={item.alt}
                title={item.title}
                text={item.text}
              />
            ))}
        </div>
        {videoSrc && (
          <div style={{ marginTop: "20px", width: "100%" }}>
            <VideoPlayer
              height={
                card.video === "project3.mp4" ||
                ("project4.mp4" && windowWidth > 800)
                  ? "450px"
                  : "250px"
              }
              src={videoSrc}
            />
          </div>
        )}
        {windowWidth < 800 && (
          <Button
            mt={"40px"}
            text={"Back to portfolio"}
            path={RouteNames.PORTFOLIO}
          />
        )}
      </div>
    </div>
  );
};
