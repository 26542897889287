import React from "react";
import style from "./Title.module.css";

export const Title = ({ title, text, mt, mb, color = "black" }) => {
  return (
    <div className={style.titleBox} style={{ marginBottom: mb, marginTop: mt }}>
      <div className={style.titleLine} />
      <div style={{ color: color }} className={style.blockTitle}>
        {title || "Title"}
      </div>
      {text && (
        <div style={{ color: color }} className={style.blockText}>
          {text}
        </div>
      )}
    </div>
  );
};
