import React from // , { useEffect, useState }
"react";
import { Title } from "../Title";
import style from "./Portfolio.module.css";
import { PORTFOLIO_LIST } from "../../common";
import { Image } from "../../ui";
import { useWidth } from "../../hooks/UseWidth";

// const MAX_REVIEWS = 3; // define the constant

export const Portfolio = () => {
  // const [showAllReviews, setShowAllReviews] = useState(false);
  const windowWidth = useWidth();

  // useEffect(() => {
  //   setShowAllReviews(!(windowWidth < 801));
  // }, [windowWidth]);

  return (
    <div className={style.portfolioContainer}>
      <div className={"container"}>
        <Title
          mb={window.innerWidth > 800 ? "40px" : "20px"}
          title={"Our projects"}
        />
        <div className={style.portfolioList}>
          {PORTFOLIO_LIST.slice(
            0,
            // showAllReviews
            //     ?
            PORTFOLIO_LIST.length
            // : MAX_REVIEWS
          ).map(({ title, text, alt, img, id, video }) => (
            <Image
              key={id}
              title={title}
              text={text}
              alt={alt}
              img={img}
              id={id}
            />
          ))}
          {/*<div style={{ display: "flex", justifyContent: "center" }}>*/}
          {/*  {windowWidth < 801 && PORTFOLIO_LIST.length > 3 && (*/}
          {/*    <button*/}
          {/*      className={"show-and-hide"}*/}
          {/*      onClick={() => setShowAllReviews(!showAllReviews)}*/}
          {/*    >*/}
          {/*      <p>{showAllReviews ? "Hide" : "Show all"}</p>*/}
          {/*    </button>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};
