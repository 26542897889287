import React from "react";
import { Facebook, Instagram, Pinterest } from "../../customIcons";
import style from "./SocialIconsList.module.css";

export const SocialIconsList = ({ fill }) => {
  return (
    <ul className={style.socialIconsList}>
      <li>
        <a
          href="https://www.instagram.com/homecraft_masters/"
          target={"_blank"}
        >
          <Instagram fill={fill} />
        </a>
      </li>
      <li>
        <a href="https://ru.pinterest.com/" target={"_blank"}>
          <Pinterest fill={fill} />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/" target={"_blank"}>
          <Facebook fill={fill} />
        </a>
      </li>
    </ul>
  );
};
