import React from "react";
import { Button } from "../ui";
import { RouteNames } from "../router";
import { Title } from "../components";

export const NotFoundPage = () => {
  return (
    <div className={"container"} style={{ marginTop: "100px" }}>
      <div className={"not-found-content"}>
        <Title title={"404 Come back home\n"} mt={"60px"} />
        <Button text={"Back to home"} path={RouteNames.HOME} />
      </div>
    </div>
  );
};
