import React from "react";

export const Designer = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_163_114)">
        <path
          d="M77.4889 71.5778L66.6667 60.8222V19.4444L61.5556 10.0444C61.1786 9.32775 60.6129 8.72771 59.9197 8.30911C59.2265 7.89052 58.4321 7.66927 57.6223 7.66927C56.8125 7.66927 56.0181 7.89052 55.3249 8.30911C54.6316 8.72771 54.066 9.32775 53.6889 10.0444L48.8889 19.4666V43.1333L8.2445 2.68886C7.93367 2.37546 7.53656 2.16173 7.10379 2.07491C6.67101 1.98809 6.22219 2.03211 5.81454 2.20137C5.40688 2.37063 5.05887 2.65746 4.81487 3.02528C4.57087 3.3931 4.44193 3.82525 4.4445 4.26664V22.2222H9.26672V25.7778H4.4445V40H9.26672V43.5555H4.4445V58.3333H9.26672V61.8889H4.4445V73.1555C4.4445 73.7449 4.67862 74.3101 5.09537 74.7269C5.51212 75.1436 6.07735 75.3777 6.66672 75.3777H75.9112C76.3525 75.3803 76.7847 75.2514 77.1525 75.0074C77.5203 74.7634 77.8072 74.4154 77.9764 74.0077C78.1457 73.6001 78.1897 73.1512 78.1029 72.7185C78.0161 72.2857 77.8023 71.8886 77.4889 71.5778ZM22.2223 57.7778V37.6444L42.3778 57.7778H22.2223ZM62.2223 62.4666H53.3334V57.0666H62.2223V62.4666ZM62.2223 53.5778H53.3334V20.5555L57.6445 12.1778L62.2223 20.5778V53.5778Z"
          fill="#FF4F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_163_114">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
