import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import style from "./ReviewsModal.module.css";
import { validation, validationEmail, validationName } from "../../common";
import {
  PUBLIC_KEY,
  REVIEWS_SERVICE_ID,
  REVIEWS_TEMPLATE_ID,
} from "../../common";
import emailjs from "@emailjs/browser";
import { Cross, Done } from "../../customIcons";

Modal.setAppElement("#root");

export const ReviewsModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log(formRef.current);
    if (formRef.current) {
      emailjs
        .sendForm(
          REVIEWS_SERVICE_ID,
          REVIEWS_TEMPLATE_ID,
          formRef.current,
          PUBLIC_KEY
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            console.log(formRef.current);
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }

    setIsSubmitted(true);
    reset(); // очистить форму
    setTimeout(() => {
      setIsSubmitted(false);
      setModalIsOpen(false);
    }, 1000);
  };

  return (
    <div className={style.reviewsModalContainer}>
      <button
        className={style.reviewsModalButton}
        onClick={() => setModalIsOpen(true)}
      >
        <p className={style.reviewsModalButtonText}>Write a review</p>
      </button>
      <Modal
        className={style.reviewsModal}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        {!isSubmitted && (
          <div className={style.reviewsModalCrossButton}>
            <Cross onClick={() => setModalIsOpen(false)} />
          </div>
        )}
        {!isSubmitted && (
          <h2
            style={{
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            Fill the form
          </h2>
        )}
        {isSubmitted ? (
          <div className={style.reviewsModalDoneContainer}>
            <Done />
            <h1 className={style.reviewsModalDoneText}>Done!</h1>
          </div>
        ) : (
          <form
            ref={formRef}
            className={style.form}
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <label className={style.formLabel} htmlFor="">
              <span>First name*</span>
              <input
                name={"reviewsName"}
                className={style.formInput}
                {...register("reviewsName", validationName)}
              />
              {errors.reviewsName && (
                <p className={style.formError}>{errors.reviewsName?.message}</p>
              )}
            </label>
            <label className={style.formLabel} htmlFor="">
              <span>Email*</span>
              <input
                name={"reviewsEmail"}
                className={style.formInput}
                {...register("reviewsEmail", validationEmail)}
              />
              {errors.reviewsEmail && (
                <p className={style.formError}>
                  {errors.reviewsEmail?.message}
                </p>
              )}
            </label>
            <label className={style.formLabel}>
              <span>Message</span>
              <textarea
                name={"reviewsMessage"}
                className={style.formMessage}
                {...register("reviewsMessage", validation)}
              />
              {errors.reviewsMessage && (
                <p className={style.formError}>
                  {errors.reviewsMessage?.message}
                </p>
              )}
            </label>
            {/*<label className={style.fileInputContainer}>*/}
            {/*  <input*/}
            {/*    type="file"*/}
            {/*    accept="image/*"*/}
            {/*    name="photo"*/}
            {/*    className={style.formInputFile}*/}
            {/*    {...register("photo")}*/}
            {/*  />*/}
            {/*  <div className={style.fileInputLabel}>Choose photo</div>*/}
            {/*  <p className={style.reviewsModalWarningMessage}>*/}
            {/*    Warning: maximum photo size 10kb*/}
            {/*  </p>*/}
            {/*</label>*/}
            <div className={style.submitButtonContainer}>
              <input
                className={style.submitButton}
                type="submit"
                value="Submit"
                disabled={!isValid}
              />
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};
