import React from "react";
import style from "./ContactInfo.module.css";
import { SocialIconsList } from "../SocialIconsList";

export const ContactInfo = () => {
  return (
    <div>
      <div className={style.contactInfoTextContainer}>
        <p>Telephone</p>
        <a href="tel:17203806636">+1 720-380-6636</a>
      </div>
      <div className={style.contactInfoTextContainer}>
        <p>E-mail</p>
        <a href="mailto:contact@homecraft-masters.com">
          contact@homecraft-masters.com
        </a>
      </div>
      <div className={style.contactInfoSchedule}>
        <p
          className={style.contactInfoScheduleTitle}
          style={{ marginBottom: "2px" }}
        >
          Working hours
        </p>
        <p className={style.contactInfoScheduleText}>
          Mon-Fri: 8:00am - 5:00pm
        </p>
      </div>
      <div>
        <SocialIconsList fill={"#3F4D63"} />
      </div>
    </div>
  );
};
