import React from "react";
import style from "./Button.module.css";
import { Link, useNavigate } from "react-router-dom";

export const Button = ({ text, path = "/", mt }) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <Link
      style={{ marginTop: mt }}
      onClick={handleClick}
      to={path}
      className={style.button}
    >
      <p className={style.buttonText}>{text}</p>
    </Link>
  );
};
