import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

export const CustomLink = ({ path, color, text }) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <NavLink onClick={handleClick} style={{ color: color }} to={path}>
      {text}
    </NavLink>
  );
};
