import React from "react";
import { Title } from "../Title";
import { WHY_CHOOSE_US_LIST } from "../../common";
import { WhyChooseUsItem } from "../WhyChooseUsItem";
import style from "./WhyChooseUsList.module.css";
import { useWidth } from "../../hooks/UseWidth";

export const WhyChooseUsList = () => {
  const windowWidth = useWidth();
  return (
    <div className={"container"}>
      <Title
        title={"Why choose us"}
        mt={windowWidth > 800 ? "100px" : "80px"}
        mb={windowWidth > 800 ? "40px" : "20px"}
      />
      <div className={style.cardList}>
        {WHY_CHOOSE_US_LIST.map((card) => (
          <WhyChooseUsItem key={card.id} card={card} />
        ))}
      </div>
    </div>
  );
};
