import React from "react";
import { AppRoutes, ScrollToTopButton, Footer, Navbar } from "./components";

export const App = () => {
  return (
    <div className={"page"}>
      <Navbar />
      <AppRoutes />
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};
