import React from "react";

export const Pinterest = ({ fill }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0527 28.72C13.3327 29.1066 14.626 29.3333 15.9993 29.3333C19.5356 29.3333 22.927 27.9285 25.4274 25.428C27.9279 22.9276 29.3327 19.5362 29.3327 16C29.3327 14.249 28.9878 12.5152 28.3177 10.8975C27.6477 9.27984 26.6656 7.80998 25.4274 6.57187C24.1893 5.33375 22.7195 4.35163 21.1018 3.68157C19.4841 3.0115 17.7503 2.66663 15.9993 2.66663C14.2484 2.66663 12.5146 3.0115 10.8969 3.68157C9.27923 4.35163 7.80937 5.33375 6.57126 6.57187C4.07077 9.07235 2.66602 12.4637 2.66602 16C2.66602 21.6666 6.22601 26.5333 11.2527 28.4533C11.1327 27.4133 11.0127 25.6933 11.2527 24.5066L12.786 17.92C12.786 17.92 12.3993 17.1466 12.3993 15.92C12.3993 14.08 13.546 12.7066 14.8527 12.7066C15.9993 12.7066 16.5327 13.5466 16.5327 14.6266C16.5327 15.7733 15.7727 17.4133 15.386 18.9866C15.1593 20.2933 16.0793 21.44 17.4127 21.44C19.786 21.44 21.626 18.9066 21.626 15.3333C21.626 12.1333 19.3327 9.94663 16.0393 9.94663C12.2793 9.94663 10.066 12.7466 10.066 15.6933C10.066 16.84 10.4393 18 11.0527 18.76C11.1727 18.84 11.1727 18.9466 11.1327 19.1466L10.746 20.6C10.746 20.8266 10.5993 20.9066 10.3727 20.7466C8.66602 20 7.67935 17.5733 7.67935 15.6133C7.67935 11.4 10.666 7.57329 16.426 7.57329C21.0127 7.57329 24.586 10.8666 24.586 15.24C24.586 19.8266 21.746 23.5066 17.6793 23.5066C16.386 23.5066 15.1193 22.8133 14.666 22L13.7727 25.16C13.466 26.3066 12.626 27.84 12.0527 28.76V28.72Z"
        fill={fill}
      />
    </svg>
  );
};
