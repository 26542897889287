import React from "react";

export const Facebook = ({ fill }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3346 16.0001C29.3346 8.64008 23.3613 2.66675 16.0013 2.66675C8.6413 2.66675 2.66797 8.64008 2.66797 16.0001C2.66797 22.4534 7.25464 27.8267 13.3346 29.0667V20.0001H10.668V16.0001H13.3346V12.6667C13.3346 10.0934 15.428 8.00008 18.0013 8.00008H21.3346V12.0001H18.668C17.9346 12.0001 17.3346 12.6001 17.3346 13.3334V16.0001H21.3346V20.0001H17.3346V29.2667C24.068 28.6001 29.3346 22.9201 29.3346 16.0001Z"
        fill={fill}
      />
    </svg>
  );
};
