import React from "react";
import style from "./Image.module.css";
import { Link, useNavigate } from "react-router-dom";
import { RouteNames } from "../../router";

export const Image = ({ img, alt, text, title, id, width, height }) => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate(RouteNames.PORTFOLIO + "/" + id);
    window.scrollTo(0, 0);
  };

  return (
    <div className={style.projectContainer}>
      <Link onClick={handleClick} to={RouteNames.PORTFOLIO + "/" + id}>
        <img
          style={{
            width: width,
            height: height,
          }}
          className={style.projectImage}
          src={img}
          alt={alt}
        />
        <div className={style.projectHovering}>
          <p className={style.projectTitle}>{title}</p>
          <p className={style.projectText}>{text}</p>
        </div>
      </Link>
    </div>
  );
};
