import React from "react";
import { AboutUsInfo, WhyChooseUsList } from "../components";

export const AboutPage = () => {
  return (
    <div className={"about-page-container-margin"}>
      <AboutUsInfo />
      <WhyChooseUsList />
    </div>
  );
};
