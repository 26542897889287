import React from "react";
import { RouteNames } from "../../router";
import style from "./FooterNavList.module.css";
import { CustomLink } from "../../ui";

export const FooterNavList = () => {
  return (
    <ul className={style.footerNavList}>
      <li>
        <CustomLink color={"white"} path={RouteNames.HOME} text={"Home"} />
      </li>
      <li>
        <CustomLink color={"white"} path={RouteNames.ABOUT} text={"About"} />
      </li>
      <li>
        <CustomLink
          color={"white"}
          path={RouteNames.PORTFOLIO}
          text={"Gallery"}
        />
      </li>
      <li>
        <CustomLink
          color={"white"}
          path={RouteNames.REVIEWS}
          text={"Reviews"}
        />
      </li>
      <li>
        <CustomLink
          color={"white"}
          path={RouteNames.CONTACTS}
          text={"Contacts"}
        />
      </li>
    </ul>
  );
};
