import React from "react";
import styles from "./WhyChooseUsItem.module.css";

export const WhyChooseUsItem = ({ card: { title, text, img, alt } }) => {
  return (
    <div className={styles.card}>
      {img}
      <h5 className={styles.cardTitle}>{title}</h5>
      <p className={styles.cardText}>{text}</p>
    </div>
  );
};
