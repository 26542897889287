import React from "react";
import style from "./ReviewsCard.module.css";

export const ReviewsCard = ({ review: { name, data, text, avatar } }) => {
  return (
    <div className={style.reviewCard}>
      {/*<img*/}
      {/*  className={style.reviewCardAvatar}*/}
      {/*  src={avatar}*/}
      {/*  alt="Avatar"*/}
      {/*  width={80}*/}
      {/*  height={80}*/}
      {/*/>*/}
      <p className={style.reviewCardName}>{name}</p>
      <p className={style.reviewCardData}>{data}</p>
      <div className={style.reviewCardLine} />
      <p className={style.reviewCardText}>{text}</p>
    </div>
  );
};
