export const validationName = {
    required: 'The field is required',
    pattern: {
        value: /^[A-Z][a-z]*$/,
        message: 'The field must have only latin letters and start with a capital letter'
    },
    minLength: {
        value: 2,
        message: 'The field must have at least 2 characters'
    },
    maxLength: {
        value: 30,
        message: 'The field must have no more than 30 characters'
    }
}

export const validationEmail = {
    required: 'The field is required',
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address'
    }
}

export const validationPhone = {
    required: 'The field is required',
    pattern: {
        value: /^(\+?\d{1,3})?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{2})[-.\s]?(\d{2})$/,
        message: 'The field must have only numbers and correct number of digits'
    }
}

export const validation = {
    required: 'The field is required',
}
