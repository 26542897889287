import React from "react";

export const FooterMobileLogo = () => {
  return (
    <svg
      width="115"
      height="32"
      viewBox="0 0 115 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="28.6177"
        width="0.91081"
        height="2.2941"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="5.64722"
        y="27.7354"
        width="0.882348"
        height="5.64702"
        transform="rotate(90 5.64722 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="5.64722"
        y="30.9119"
        width="0.882348"
        height="5.64702"
        transform="rotate(90 5.64722 30.9119)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="7.05859"
        y="27.7354"
        width="0.882347"
        height="4.0588"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="11.8235"
        y="30.2058"
        width="0.882347"
        height="1.58823"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="11.8235"
        y="27.7354"
        width="0.882347"
        height="1.58823"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="11.8235"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 11.8235 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="11.8235"
        y="29.3237"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 11.8235 29.3237)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="14.1174"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="18.8823"
        y="27.7354"
        width="0.882347"
        height="4.0588"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="18.8823"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 18.8823 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="18.8823"
        y="29.3237"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 18.8823 29.3237)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="21.1763"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="25.9409"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 25.9409 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="25.4116"
        y="29.3237"
        width="0.882348"
        height="4.23527"
        transform="rotate(90 25.4116 29.3237)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="29.2942"
        y="28.6177"
        width="0.882347"
        height="3.17645"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="32.1177"
        y="27.7354"
        width="0.882348"
        height="4.76468"
        transform="rotate(90 32.1177 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="35.8528"
        y="28.6357"
        width="0.900245"
        height="3.24088"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="39.2737"
        y="27.7354"
        width="0.900245"
        height="3.42093"
        transform="rotate(90 39.2737 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="42.6948"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        transform="rotate(90 42.6948 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="40.1738"
        y="31.8767"
        width="0.900245"
        height="3.24088"
        transform="rotate(-180 40.1738 31.8767)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="43.595"
        y="31.8767"
        width="0.900245"
        height="3.24088"
        transform="rotate(-180 43.595 31.8767)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="71.1421"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="76.0037"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="76.0037"
        y="27.7354"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="76.0037"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 76.0037 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="76.0037"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 76.0037 29.356)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="45.0356"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="49.8967"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="49.8967"
        y="29.0884"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="49.8967"
        y="27.7354"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="49.8967"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 49.8967 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="49.8967"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 49.8967 29.356)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="64.8408"
        y="27.7354"
        width="0.900245"
        height="4.14112"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="69.7021"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="69.7021"
        y="30.9763"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 30.9763)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="69.7021"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 69.7021 29.356)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="52.2375"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="56.1985"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="56.1985"
        y="30.9766"
        width="0.900245"
        height="3.96108"
        transform="rotate(90 56.1985 30.9766)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="57.0986"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 57.0986 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="57.0986"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 57.0986 29.356)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="78.3445"
        y="27.7354"
        width="0.900245"
        height="2.52068"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="82.3057"
        y="30.2561"
        width="0.900245"
        height="1.62044"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="82.3057"
        y="30.9763"
        width="0.900245"
        height="3.96108"
        transform="rotate(90 82.3057 30.9763)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="83.2058"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 83.2058 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="83.2058"
        y="29.356"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 83.2058 29.356)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="60.5195"
        y="28.6357"
        width="0.900245"
        height="3.24088"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="63.4001"
        y="27.7354"
        width="0.900245"
        height="4.86132"
        transform="rotate(90 63.4001 27.7354)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="54.1177"
        y="11.5"
        width="3.38235"
        height="12.1764"
        fill="white"
      />
      <rect
        x="66.9705"
        y="8.11768"
        width="3.38235"
        height="12.8529"
        transform="rotate(90 66.9705 8.11768)"
        fill="white"
      />
      <rect
        x="79.8235"
        y="8.11768"
        width="3.38235"
        height="9.47057"
        transform="rotate(90 79.8235 8.11768)"
        fill="white"
      />
      <rect
        x="70.3528"
        y="23.6765"
        width="3.38235"
        height="12.1764"
        transform="rotate(-180 70.3528 23.6765)"
        fill="white"
      />
      <rect
        x="83.2058"
        y="23.6765"
        width="3.38235"
        height="12.1764"
        transform="rotate(-180 83.2058 23.6765)"
        fill="white"
      />
      <rect y="4.73535" width="3.38235" height="18.9411" fill="white" />
      <rect
        x="21.647"
        y="8.11768"
        width="3.38235"
        height="21.647"
        transform="rotate(90 21.647 8.11768)"
        fill="white"
      />
      <rect
        x="21.647"
        y="23.6765"
        width="3.38235"
        height="13.5294"
        transform="rotate(-180 21.647 23.6765)"
        fill="white"
      />
      <path
        d="M91.6477 13.0319C91.6477 13.0319 105.416 12.9844 105.501 13.0319C105.586 13.0794 103.853 8.35491 98.5803 8.30743C93.296 8.24808 91.6477 13.0319 91.6477 13.0319Z"
        fill="#FF4F00"
      />
      <path
        d="M107.283 16.5694C107.283 16.5694 91.3084 16.6407 91.2235 16.5694C91.1387 16.4982 93.1385 22.8252 99.2591 22.8964C105.38 22.9677 107.283 16.5694 107.283 16.5694Z"
        fill="#FF4F00"
      />
      <path
        d="M105.67 19.5013L109.209 22.2553L111.027 16.5574H107.21"
        fill="#FF4F00"
      />
      <path
        d="M90.7021 7.17961L88.6174 3.96272C88.6174 3.96272 99.5619 -5.20145 109.937 4.34252C103.804 3.47597 98.9801 3.72529 96.6046 4.30695C94.12 4.92422 92.3141 6.0282 92.3141 6.0282C91.599 6.45554 91.0536 6.88285 90.7021 7.17961Z"
        fill="#FF4F00"
      />
      <path
        d="M108.858 11.0019C108.337 9.92166 106.846 6.8115 103.428 5.30394C101.21 4.31867 99.0772 4.4018 98.0106 4.44929C94.8958 4.59173 93.1384 5.71941 93.0414 5.52948C92.8839 5.20897 97.95 1.71905 103.307 1.70718C105.743 1.69531 107.234 2.40756 107.622 2.60936C107.888 2.73993 108.725 3.17916 109.925 4.33061C111.015 5.37522 114.554 8.85332 114.954 14.575C114.979 14.848 114.979 15.0616 114.991 15.2041C115.027 16.2724 115.1 20.8189 111.973 25.0211C109.137 28.8434 104.665 30.9089 104.156 30.351C103.671 29.8168 107.428 27.4308 109.197 22.2552C109.646 20.9613 111.258 16.0113 108.858 11.0019Z"
        fill="#FF4F00"
      />
      <path
        d="M104.022 30.3036L101.901 27.0629C102.75 26.9323 103.404 26.7306 103.828 26.5881C104.531 26.3507 106.277 25.769 107.525 24.5582C109.404 22.742 109.537 20.1067 109.719 20.1542C109.888 20.2017 109.391 22.3384 109.694 22.4215C109.828 22.469 110.082 22.0654 110.325 21.638C111.185 22.3977 111.428 23.0863 111.5 23.5492C111.852 25.8284 108.979 28.7011 104.156 30.3392"
        fill="#FF4F00"
      />
      <path
        d="M104.677 19.5368L109.525 23.098L111.67 20.89L105.028 18.0173L104.677 19.4656"
        fill="#FF4F00"
      />
      <rect x="27.0586" y="11.5" width="3.49145" height="8.7941" fill="white" />
      <rect
        x="48.7056"
        y="8.11768"
        width="3.38235"
        height="21.647"
        transform="rotate(90 48.7056 8.11768)"
        fill="white"
      />
      <rect
        x="48.7056"
        y="20.2942"
        width="3.38235"
        height="21.647"
        transform="rotate(90 48.7056 20.2942)"
        fill="white"
      />
      <rect
        x="48.7056"
        y="20.2942"
        width="3.49146"
        height="8.7941"
        transform="rotate(-180 48.7056 20.2942)"
        fill="white"
      />
    </svg>
  );
};
