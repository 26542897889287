import React, { useState } from "react";
import Modal from "react-modal";
import style from "./ImageModal.module.css";

export const ImageModal = ({ img, alt, text, title, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={style.imageModalContainer}>
      <div onClick={() => setIsOpen(true)}>
        <img
          className={style.projectImage}
          src={img}
          alt={alt}
          width={448}
          height={375}
        />
      </div>
      <Modal
        overlayClassName={style.projectModalOverlay}
        className={style.projectModal}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <img
          onClick={() => setIsOpen(false)}
          className={style.projectModalImage}
          src={img}
          alt={alt}
          width={448}
          height={375}
        />
      </Modal>
    </div>
  );
};
