import React from "react";

export const Error = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.165 65.0001C119.165 94.9136 94.9122 119.167 64.9987 119.167C35.0852 119.167 10.832 94.9136 10.832 65.0001C10.832 35.0865 35.0852 10.8334 64.9987 10.8334C94.9122 10.8334 119.165 35.0865 119.165 65.0001Z"
        fill="#DB0909"
      />
      <path
        d="M83 83L48 48M83 48L48 83"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
