import React from "react";
import style from "./OurStory.module.css";
import { Title } from "../Title";
import { Button } from "../../ui";
import { RouteNames } from "../../router";
import { useImage } from "../../hooks/useImage";

export const OurStory = () => {
  const background = useImage("our-srory-background.png");
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={style.ourStoryContainer}
    >
      <div className="container">
        <Title
          mb={"40px"}
          title={"Our story"}
          text={"Why we started it?"}
          color={"white"}
        />
        <p className={style.ourStoryText}>
          HomeCraft Masters is a family owned company with more than 20 in a
          custom kitchen industry. We are originally came from Ukraine, where we
          grub with us all the experience, inspiration and unique European
          design. With your inspiration and ideas, we will help you to reach
          your dreams. So, you will see by yourself why our credo is “A good is
          an enemy of the Best”
        </p>
        <Button path={RouteNames.ABOUT} text={"Learn more"} />
      </div>
    </div>
  );
};
