import React from "react";

export const Cabinet = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.6667 26.6667H33.3334V20.0001H46.6667V26.6667ZM66.6667 13.3334V66.6667C66.6667 70.3667 63.7 73.3334 60 73.3334H20C16.3 73.3334 13.3334 70.3667 13.3334 66.6667V13.3334C13.3334 11.5653 14.0358 9.86961 15.286 8.61937C16.5362 7.36913 18.2319 6.66675 20 6.66675H60C61.7682 6.66675 63.4638 7.36913 64.7141 8.61937C65.9643 9.86961 66.6667 11.5653 66.6667 13.3334ZM60 43.3334H20V66.6667H60V43.3334ZM60 13.3334H20V36.6667H60V13.3334ZM46.6667 50.0001H33.3334V56.6667H46.6667V50.0001Z"
        fill="#FF4F00"
      />
    </svg>
  );
};
