import React from "react";
import style from "./OurProjects.module.css";
import { Title } from "../Title";
import { Button, Image } from "../../ui";
import { RouteNames } from "../../router";
import image from "../../assets/projects/1/1.jpg";
import image1 from "../../assets/projects/4/main.jpg";
import image2 from "../../assets/projects/3/4.jpg";
import { useWidth } from "../../hooks/UseWidth";

export const OurProjects = () => {
  const windowWidth = useWidth();
  return (
    <div className={style.ourProjectContainer}>
      <div className="container">
        <Title
          title={"Our projects"}
          mb={windowWidth > 800 ? "40px" : "20px"}
        />
        <div className={style.ourProjectList}>
          <Image
            id={3}
            img={image2}
            alt={"Project"}
            text={"July 2021"}
            title={"Denver"}
            width={408}
            height={306}
          />
          <Image
            id={1}
            img={image}
            alt={"Project"}
            text={"Feb 2023"}
            title={"Winter Park"}
            width={408}
            height={306}
          />
          <Image
            id={4}
            img={image1}
            alt={"Project"}
            text={"October 2020"}
            title={"Aurora"}
            width={408}
            height={306}
          />
        </div>
        <Button text={"See all"} path={RouteNames.PORTFOLIO} />
      </div>
    </div>
  );
};
