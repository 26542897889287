import React from "react";
import { Title, ReviewsList, ReviewsModal } from "../components";
import "./style.css";
import { useWidth } from "../hooks/UseWidth";

export const ReviewsPage = () => {
  const windowWidth = useWidth();
  return (
    <div className={"container container-margin"}>
      <Title mb={windowWidth ? "40px" : "20px"} title={"Reviews"} />
      <ReviewsList />
      <ReviewsModal />
    </div>
  );
};
