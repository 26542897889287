import React from "react";
import { Title } from "../Title";
import style from "./AboutUsInfo.module.css";
import { useWidth } from "../../hooks/UseWidth";
import { useImage } from "../../hooks/useImage";

export const AboutUsInfo = () => {
  const background = useImage("our-srory-background.png");
  const windowWidth = useWidth();
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={style.aboutUsInfoContainer}
    >
      <div className={"container"}>
        <Title
          color={"white"}
          title={"About us"}
          mb={windowWidth > 800 ? "36px" : "20px"}
        />
        <div className={style.aboutUsInfoTextContainer}>
          <p className={style.aboutUsInfoText}>
            HomeCraft Masters is a family owned company with more than 20 in a
            custom kitchen industry. We are originally came from Ukraine, where
            we grub with us all the experience, inspiration and unique European
            design. With your inspiration and ideas, we will help you to reach
            your dreams. So, you will see by yourself why our credo is “A good
            is an enemy of the Best”
          </p>
        </div>
      </div>
    </div>
  );
};
