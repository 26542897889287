import React from "react";
import style from "./WelcomeToUs.module.css";
import { Title } from "../Title";
import { Button } from "../../ui";
import { RouteNames } from "../../router";
import { useWidth } from "../../hooks/UseWidth";

export const WelcomeToUs = () => {
  const windowWidth = useWidth();
  return (
    <div className={style.welcomeToUsWrapper}>
      <div className={"container"}>
        <Title
          mb={windowWidth > 800 ? "40px" : "20px"}
          title={"Welcome to us"}
        />
        <div className={style.welcomeToUsTextContainer}>
          <p className={style.welcomeToUsText}>
            We all have dreams and love when they come true. So, we are
            welcoming you to such a place where all your kitchen dreams will
            come true and real!! With your ideas and our experience, we can help
            you to create your unique and very special masterpiece. That will
            perfectly fit your lifestyle, habits and expectations.
          </p>
          <p className={style.welcomeToUsText}>
            Our company works only with the most quality and wide range
            materials and hardware, so you will get the highest performance from
            your custom kitchen.
          </p>
        </div>
        <Button text={"Schedule a visit"} path={RouteNames.CONTACTS} />
      </div>
    </div>
  );
};
