import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from "react";

export const useImage = (src) => {
  const [imageSrc, setImageSrc] = useState(null);
  const storage = getStorage();
  const image = ref(storage, src);

  useEffect(() => {
    getDownloadURL(image).then((url) => {
      setImageSrc(url);
    });
  }, [image]);

  return imageSrc;
};
