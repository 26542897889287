import React from "react";
import { ContactInfo, Form, Title } from "../components";
import "./style.css";
import { useWidth } from "../hooks/UseWidth";

export const ContactPage = () => {
  const windowWidth = useWidth();
  return (
    <div style={{ marginBottom: "100px" }} className={"container"}>
      <Title
        title={"Contact us"}
        text={"Send us a message and we will get back to you!"}
        mb={windowWidth ? "40px" : "20px"}
        mt={windowWidth > 800 ? "160px" : "140px"}
      />
      <div className={"contact-page-content"}>
        <ContactInfo />
        <Form />
      </div>
    </div>
  );
};
