import React from "react"; // , { useEffect, useState }
import style from "./ReviewsList.module.css";
import { ReviewsCard } from "../ReviewCard";
import { useWidth } from "../../hooks/UseWidth";
import { useImage } from "../../hooks/useImage";

// const MAX_REVIEWS = 3;

export const ReviewsList = () => {
  const user = useImage("user.svg");
  const REVIEWS_LIST = [
    {
      id: 1,
      name: "Oliver",
      data: "20 Mar 2023",
      text: "I would like to express my gratitude to this company for their excellent work! The kitchen has been done with great attention to detail and I feel like I got the highest quality furniture. I was also very pleased with the service the company provides. I recommend this company to everyone. Thank you for your excellent work!",
      avatar: user,
    },
    {
      id: 2,
      name: "Maria",
      data: "17 Apr 2023",
      text: "I would happily recommend this company to anyone looking for quality furniture. The furniture looks incredibly cozy and comfortable, and it is made of high quality materials. I was pleasantly surprised by how quickly my order was completed.",
      avatar: user,
    },
    {
      id: 3,
      name: "Adam",
      data: "03 Mar 2023",
      text: "The furniture looks very nice and modern, and most importantly, it is functional and comfortable to use. I received excellent service from start to finish - the company's employees were professional and attentive to all my needs.",
      avatar: user,
    },
    {
      id: 4,
      name: "Victoria",
      data: "26 Jan 2023",
      text: "I ordered a living room set from this company. The furniture looks very nice and stylish. Every detail of the furniture has been thought out to the smallest detail, and I feel that I received the furniture of the highest quality. Employees of the companies are always ready to help with all questions.",
      avatar: user,
    },
  ];
  return <List REVIEWS_LIST={REVIEWS_LIST} />;
};

const List = ({ REVIEWS_LIST = REVIEWS_LIST }) => {
  // const [showAllReviews, setShowAllReviews] = useState(false);
  const windowWidth = useWidth();

  // useEffect(() => {
  //   setShowAllReviews(!(windowWidth < 801));
  // }, [windowWidth]);

  return (
    <div className={style.reviewsList}>
      {REVIEWS_LIST.slice(
        0,
        // showAllReviews ?
        REVIEWS_LIST.length
        // : MAX_REVIEWS
      ).map((review) => (
        <ReviewsCard key={review.id} review={review} />
      ))}
      {/*{windowWidth < 801 && (*/}
      {/*  <div style={{ display: "flex", justifyContent: "center" }}>*/}
      {/*    <button*/}
      {/*      className={"show-and-hide"}*/}
      {/*      onClick={() => setShowAllReviews(!showAllReviews)}*/}
      {/*    >*/}
      {/*      <p>{showAllReviews ? "Hide" : "Show all"}</p>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
