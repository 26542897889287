import React, { useState, useEffect } from "react";
import styles from "./ScrollToTopButton.module.css";
import { ScrollToTopButtonIcon } from "../../customIcons/ScrollToTopButton";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${styles["scrollToTop"]} ${isVisible ? styles.visible : ""}`}
      onClick={handleClick}
    >
      <ScrollToTopButtonIcon />
    </div>
  );
};
