import React from "react";

export const FooterLogo = () => {
  return (
    <svg
      width="170"
      height="48"
      viewBox="0 0 170 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="42.3044"
        width="1.34642"
        height="3.39129"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="8.34804"
        y="41"
        width="1.30434"
        height="8.34779"
        transform="rotate(90 8.34804 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="8.34804"
        y="45.6956"
        width="1.30434"
        height="8.34779"
        transform="rotate(90 8.34804 45.6956)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="10.4345"
        y="41"
        width="1.30434"
        height="5.99997"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="17.4781"
        y="44.6521"
        width="1.30434"
        height="2.34782"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="17.4781"
        y="41"
        width="1.30434"
        height="2.34782"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="17.4781"
        y="41"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 17.4781 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="17.4781"
        y="43.3477"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 17.4781 43.3477)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="20.8695"
        y="42.3044"
        width="1.30434"
        height="4.69563"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="27.913"
        y="41"
        width="1.30434"
        height="5.99997"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="27.913"
        y="41"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 27.913 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="27.913"
        y="43.3477"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 27.913 43.3477)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="31.3039"
        y="42.3044"
        width="1.30434"
        height="4.69563"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="38.3474"
        y="41"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 38.3474 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="37.565"
        y="43.3477"
        width="1.30434"
        height="6.26084"
        transform="rotate(90 37.565 43.3477)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="43.3046"
        y="42.3044"
        width="1.30434"
        height="4.69563"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="47.4782"
        y="41"
        width="1.30434"
        height="7.04345"
        transform="rotate(90 47.4782 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="53"
        y="42.3311"
        width="1.3308"
        height="4.79087"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="58.0569"
        y="41"
        width="1.3308"
        height="5.05703"
        transform="rotate(90 58.0569 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="63.1141"
        y="41"
        width="1.3308"
        height="3.72624"
        transform="rotate(90 63.1141 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="59.3877"
        y="47.1218"
        width="1.3308"
        height="4.79087"
        transform="rotate(-180 59.3877 47.1218)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="64.4449"
        y="47.1218"
        width="1.3308"
        height="4.79087"
        transform="rotate(-180 64.4449 47.1218)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="105.167"
        y="41"
        width="1.3308"
        height="6.12167"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="112.354"
        y="44.7263"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="112.354"
        y="41"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="112.354"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 112.354 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="112.354"
        y="43.3958"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 112.354 43.3958)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="66.5742"
        y="41"
        width="1.3308"
        height="6.12167"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="73.7602"
        y="44.7263"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="73.7602"
        y="43"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="73.7602"
        y="41"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="73.7602"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 73.7602 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="73.7602"
        y="43.3958"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 73.7602 43.3958)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="95.8517"
        y="41"
        width="1.3308"
        height="6.12167"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="103.038"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 103.038 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="103.038"
        y="45.791"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 103.038 45.791)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="103.038"
        y="43.3958"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 103.038 43.3958)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="77.2206"
        y="41"
        width="1.3308"
        height="3.72624"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="83.0761"
        y="44.7263"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="83.0761"
        y="45.791"
        width="1.3308"
        height="5.85551"
        transform="rotate(90 83.0761 45.791)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="84.4067"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 84.4067 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="84.4067"
        y="43.3958"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 84.4067 43.3958)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="115.814"
        y="41"
        width="1.3308"
        height="3.72624"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="121.669"
        y="44.7263"
        width="1.3308"
        height="2.39544"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="121.669"
        y="45.791"
        width="1.3308"
        height="5.85551"
        transform="rotate(90 121.669 45.791)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="123"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 123 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="123"
        y="43.3958"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 123 43.3958)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="89.4636"
        y="42.3308"
        width="1.3308"
        height="4.79087"
        fill="white"
        fillOpacity="0.7"
      />
      <rect
        x="93.7221"
        y="41"
        width="1.3308"
        height="7.18631"
        transform="rotate(90 93.7221 41)"
        fill="white"
        fillOpacity="0.7"
      />
      <rect x="80" y="17" width="5" height="18" fill="white" />
      <rect
        x="99"
        y="12"
        width="5"
        height="19"
        transform="rotate(90 99 12)"
        fill="white"
      />
      <rect
        x="118"
        y="12"
        width="5"
        height="14"
        transform="rotate(90 118 12)"
        fill="white"
      />
      <rect
        x="104"
        y="35"
        width="5"
        height="18"
        transform="rotate(-180 104 35)"
        fill="white"
      />
      <rect
        x="123"
        y="35"
        width="5"
        height="18"
        transform="rotate(-180 123 35)"
        fill="white"
      />
      <rect y="7" width="5" height="28" fill="white" />
      <rect
        x="32"
        y="12"
        width="5"
        height="32"
        transform="rotate(90 32 12)"
        fill="white"
      />
      <rect
        x="32"
        y="35"
        width="5"
        height="20"
        transform="rotate(-180 32 35)"
        fill="white"
      />
      <path
        d="M135.479 19.2646C135.479 19.2646 155.833 19.1944 155.958 19.2646C156.083 19.3348 153.521 12.3508 145.727 12.2806C137.916 12.1929 135.479 19.2646 135.479 19.2646Z"
        fill="#FF4F00"
      />
      <path
        d="M158.592 24.4938C158.592 24.4938 134.978 24.5991 134.852 24.4938C134.727 24.3885 137.683 33.7415 146.731 33.8468C155.779 33.952 158.592 24.4938 158.592 24.4938Z"
        fill="#FF4F00"
      />
      <path
        d="M156.209 28.8282L161.44 32.8993L164.128 24.4763H158.484"
        fill="#FF4F00"
      />
      <path
        d="M134.082 10.6134L131 5.85795C131 5.85795 147.179 -7.68912 162.515 6.41939C153.45 5.13839 146.319 5.50696 142.807 6.3668C139.134 7.27929 136.465 8.91127 136.465 8.91127C135.408 9.54299 134.601 10.1747 134.082 10.6134Z"
        fill="#FF4F00"
      />
      <path
        d="M160.921 16.2639C160.151 14.6671 157.947 10.0694 152.894 7.84085C149.616 6.38438 146.462 6.50727 144.886 6.57746C140.281 6.78803 137.683 8.45503 137.54 8.17427C137.307 7.70048 144.796 2.54145 152.715 2.5239C156.317 2.50635 158.52 3.55924 159.094 3.85756C159.488 4.05058 160.724 4.69988 162.498 6.40202C164.11 7.94624 169.342 13.0878 169.933 21.5459C169.969 21.9495 169.969 22.2653 169.987 22.4758C170.041 24.0552 170.148 30.776 165.526 36.988C161.333 42.6384 154.722 45.6917 153.97 44.867C153.253 44.0773 158.807 40.5502 161.423 32.8993C162.086 30.9866 164.469 23.6691 160.921 16.2639Z"
        fill="#FF4F00"
      />
      <path
        d="M153.772 44.7967L150.637 40.0061C151.891 39.813 152.859 39.5148 153.486 39.3042C154.525 38.9532 157.105 38.0934 158.95 36.3035C161.727 33.6187 161.924 29.723 162.193 29.7932C162.444 29.8634 161.709 33.022 162.157 33.1449C162.354 33.2151 162.731 32.6184 163.089 31.9867C164.361 33.1097 164.719 34.1276 164.827 34.812C165.346 38.1811 161.1 42.4277 153.969 44.8493"
        fill="#FF4F00"
      />
      <path
        d="M154.74 28.8807L161.906 34.1451L165.077 30.8811L155.259 26.6345L154.74 28.7754"
        fill="#FF4F00"
      />
      <rect x="40" y="17" width="5.16129" height="13" fill="white" />
      <rect
        x="72"
        y="12"
        width="5"
        height="32"
        transform="rotate(90 72 12)"
        fill="white"
      />
      <rect
        x="72"
        y="30"
        width="5"
        height="32"
        transform="rotate(90 72 30)"
        fill="white"
      />
      <rect
        x="72"
        y="30"
        width="5.16129"
        height="13"
        transform="rotate(-180 72 30)"
        fill="white"
      />
    </svg>
  );
};
