import React from "react";

export const ScrollToTopButtonIcon = () => {
  return (
    <svg
      width="34"
      height="20"
      viewBox="0 0 34 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333289 16.6667L17 -7.28523e-07L33.6666 16.6667L30.7083 19.625L17 5.91667L3.29162 19.625L0.333289 16.6667Z"
        fill="#FF4F00"
      />
    </svg>
  );
};
