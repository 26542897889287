import React from "react";
import { useParams } from "react-router-dom";
import { PORTFOLIO_LIST } from "../common";
import { AboutProject } from "../components/AboutProject/AboutProject";

export const ProjectPage = () => {
  let card;
  const { id } = useParams();
  PORTFOLIO_LIST.map((item) => {
    if (String(item.id) === String(id)) {
      card = item;
    }
  });
  return (
    <div className={"container-margin"}>
      <div className={"container"}>
        {card && <AboutProject key={card.id} card={card} />}
      </div>
    </div>
  );
};
