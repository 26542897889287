import React, { useRef } from "react";
import style from "./VideoPlayer.module.css";

export const VideoPlayer = ({ src, height }) => {
  const videoRef = useRef(null);

  return (
    <div>
      <video
        style={{ width: "100%", height: height }}
        ref={videoRef}
        src={src}
        controls
      />
    </div>
  );
};
