import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteNames, routes } from "../../router";

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, element: Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to={RouteNames.HOME} replace />} />
    </Routes>
  );
};
