import React from "react";
import { RouteNames } from "../../router";
import style from "./NavList.module.css";
import { CustomLink } from "../../ui";

export const NavList = ({ setNav, nav }) => {
  return (
    <ul
      className={
        nav
          ? [style.menu, style.active, style.navList].join(" ")
          : [style.menu, style.navList].join(" ")
      }
    >
      <li onClick={() => setNav(false)}>
        <CustomLink color={"black"} path={RouteNames.HOME} text={"Home"} />
      </li>
      <li onClick={() => setNav(false)}>
        <CustomLink color={"black"} path={RouteNames.ABOUT} text={"About"} />
      </li>
      <li onClick={() => setNav(false)}>
        <CustomLink
          color={"black"}
          path={RouteNames.PORTFOLIO}
          text={"Gallery"}
        />
      </li>
      <li onClick={() => setNav(false)}>
        <CustomLink
          color={"black"}
          path={RouteNames.REVIEWS}
          text={"Reviews"}
        />
      </li>
      <li onClick={() => setNav(false)}>
        <CustomLink
          color={"black"}
          path={RouteNames.CONTACTS}
          text={"Contacts"}
        />
      </li>
    </ul>
  );
};
