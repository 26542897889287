import React from "react";

export const Cross = ({ fill, ...rest }) => {
  return (
    <svg
      {...rest}
      style={{ cursor: "pointer" }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ? fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 20L4 4M20 4L4 20"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
