import React from "react";
import style from "./Footer.module.css";
import { FooterNavList } from "../FooterNavList";
import { Link, useNavigate } from "react-router-dom";
import { SocialIconsList } from "../SocialIconsList";
import { useWidth } from "../../hooks/UseWidth";
import { FooterLogo, FooterMobileLogo } from "../../customIcons";

export const Footer = () => {
  const screenWidth = useWidth();
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/");
    window.scrollTo(0, 0);
  };
  return (
    <footer className={style.footer}>
      <div className={"container"}>
        <div className={style.footerSocialInfo}>
          <Link onClick={handleClick} to={"/"}>
            {screenWidth > 800 ? <FooterLogo /> : <FooterMobileLogo />}
          </Link>
          <SocialIconsList fill={"#fff"} />
        </div>
        <div className={style.line} />
        <FooterNavList />
        <div className={style.footerInfo}>
          <ul>
            <li>
              <a href="tel:+17203806636">+1 720-380-6636</a>
            </li>
            <li>
              <a href="mailto:contact@homecraft-masters.com">
                contact@homecraft-masters.com
              </a>
            </li>
          </ul>
        </div>
        <div className={style.line} />
        <div className={style.footerCopyRight}>
          <p>©Homecraft-masters.com, all rights reserved 2023</p>
          <div className={style.footerLinkAuthor}>
            <a
              target={"_blank"}
              href="https://ru.freepik.com/free-photo/mosaic-wood-pattern_4101422.htm#page=7&query=asymmetric%20form%20background&position=25&from_view=search&track=ais"
            >
              Image from rawpixel.com on Freepik
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
