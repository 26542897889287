import { Cabinet, Designer, Shield } from "../../customIcons";

export const WHY_CHOOSE_US_LIST = [
  {
    id: 1,
    img: <Cabinet />,
    alt: "Why Choose Us",
    title: "Join us for trouble-free cabinets",
    text: "When it comes to cabinet organization and location in your kitchen, you don’t need to worry about it. We’ll take care of it and make sure you will get as much from the cabinet as you need.",
  },
  {
    id: 2,
    img: <Shield />,
    alt: "Why Choose Us",
    title: "Quality that you’ve deserved",
    text: "It is our top priority to give you the best quality and reliability. We are choosing a high end material that are scratch resistant, durable and nice looking.",
  },
  {
    id: 3,
    img: <Designer />,
    alt: "Why Choose Us",
    title: "Authentic design",
    text: "The design is where a miracle happens. It is the most important part of the whole project.  So, we are very delicate with it because your kitchen is a reflection of you, that perfectly fits your home.",
  },
];
