import React from "react";
import { useImage } from "../../hooks/useImage";
import { useWidth } from "../../hooks/UseWidth";

export const Hero = () => {
  const hero = useImage("hero.JPG");
  const screenWidth = useWidth();
  return (
    <div style={{ marginTop: screenWidth > 800 ? "100px" : "80px" }}>
      <img src={hero} alt="Hero" width={"100%"} />
    </div>
  );
};
