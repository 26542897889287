import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import style from "./Form.module.css";
import {
  validation,
  validationEmail,
  validationName,
  validationPhone,
} from "../../common";
import {
  PUBLIC_KEY,
  CONTACT_TEMPLATE_ID,
  CONTACT_SERVICE_ID,
} from "../../common";
import Modal from "react-modal";
import { Done, Error } from "../../customIcons";
import { Loader } from "../Loader";
Modal.setAppElement("#root");

export const Form = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });
  const form = useRef();

  const sendEmail = () => {
    setIsLoading(true);
    form.current &&
      emailjs
        .sendForm(
          CONTACT_SERVICE_ID,
          CONTACT_TEMPLATE_ID,
          form.current,
          PUBLIC_KEY
        )
        .then((res) => {
          console.log(res.text);
          setIsLoading(false);
          setModalIsOpen(true);
          setIsSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setModalIsOpen(true);
          setIsSuccess(false);
        })
        .finally(() => {
          setTimeout(() => {
            setModalIsOpen(false);
          }, 1000);
          reset();
        });
  };

  return (
    <>
      <form
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
        className={style.form}
      >
        <label className={style.formLabel} htmlFor="">
          <span>First Name*</span>
          <input
            name="firstName"
            className={style.formInput}
            {...register("firstName", validationName)}
          />
          {errors.firstName && (
            <p className={style.formError}>{errors.firstName?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>Last Name*</span>
          <input
            name="lastName"
            className={style.formInput}
            {...register("lastName", validationName)}
          />
          {errors.lastName && (
            <p className={style.formError}>{errors.lastName?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>Email*</span>
          <input
            name="email"
            className={style.formInput}
            {...register("email", validationEmail)}
          />
          {errors.email && (
            <p className={style.formError}>{errors.email?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>Phone number*</span>
          <input
            name="phone"
            className={style.formInput}
            {...register("phone", validationPhone)}
          />
          {errors.phone && (
            <p className={style.formError}>{errors.phone?.message}</p>
          )}
        </label>
        <div className={style.checkboxGroup}>
          <p className={style.checkboxGroupTitle}>
            Preferred Method of Contact:
          </p>
          <label className={style.formCheckboxLabel}>
            <input
              className={style.formInputCheckbox}
              type="checkbox"
              value={"call"}
              name={"contactCall"}
              width={18}
            />
            <span className={style.checkboxText}>Call</span>
          </label>
          <label className={style.formCheckboxLabel}>
            <input
              className={style.formInputCheckbox}
              type="checkbox"
              value={"email"}
              name={"contactEmail"}
              width={18}
            />
            <span className={style.checkboxText}>Email</span>
          </label>
          <label className={style.formCheckboxLabel}>
            <input
              className={style.formInputCheckbox}
              type="checkbox"
              value={"text"}
              name={"contactText"}
              width={18}
            />
            <span className={style.checkboxText}>Text</span>
          </label>
        </div>
        <label className={style.formLabel}>
          <span>Message*</span>
          <textarea
            name={"message"}
            className={style.formMessage}
            {...register("message", validation)}
          />
          {errors.message && (
            <p className={style.formError}>{errors.message?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>Street address*</span>
          <input
            name={"address"}
            className={style.formInput}
            {...register("address", validation)}
          />
          {errors.address && (
            <p className={style.formError}>{errors.address?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>City*</span>
          <input
            name={"city"}
            className={style.formInput}
            {...register("city", validation)}
          />
          {errors.city && (
            <p className={style.formError}>{errors.city?.message}</p>
          )}
        </label>
        <label className={style.formLabel} htmlFor="">
          <span>State*</span>
          <input
            name={"state"}
            className={style.formInput}
            {...register("state", validation)}
          />
          {errors.state && (
            <p className={style.formError}>{errors.state?.message}</p>
          )}
        </label>
        <div className={style.submitButtonContainer}>
          <input
            className={style.submitButton}
            type="submit"
            value="Submit"
            disabled={!isValid}
          />
        </div>
      </form>
      {isLoading && <Loader />}
      <Modal
        className={style.reviewsModal}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className={style.reviewsModalContainer}>
          {isSuccess ? (
            <div className={style.reviewsModalDoneContainer}>
              <Done />
              <h1 className={style.reviewsModalDoneText}>Done!</h1>
            </div>
          ) : (
            <div className={style.reviewsModalDoneContainer}>
              <Error />
              <h1 className={style.reviewsModalDoneText}>Error!</h1>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
