import {
  AboutPage,
  ContactPage,
  PortfolioPage,
  HomePage,
  NotFoundPage,
  ReviewsPage,
  ProjectPage,
} from "../pages";

export const RouteNames = {
  HOME: "/",
  ABOUT: "/about",
  CONTACTS: "/contacts",
  REVIEWS: "/reviews",
  PORTFOLIO: "/gallery",
  PORTFOLIO_PROJECT: "/gallery/:id",
  NOT_FOUND: "*",
};

export const routes = [
  {
    path: RouteNames.HOME,
    element: HomePage,
  },
  {
    path: RouteNames.ABOUT,
    element: AboutPage,
  },
  {
    path: RouteNames.CONTACTS,
    element: ContactPage,
  },
  {
    path: RouteNames.REVIEWS,
    element: ReviewsPage,
  },
  {
    path: RouteNames.PORTFOLIO,
    element: PortfolioPage,
  },
  {
    path: RouteNames.NOT_FOUND,
    element: NotFoundPage,
  },
  {
    path: RouteNames.PORTFOLIO_PROJECT,
    element: ProjectPage,
  },
];
