import project1 from "../../assets/projects/1/main.jpg";
import project1_child1 from "../../assets/projects/1/1.jpg";
import project1_child2 from "../../assets/projects/1/2.jpg";
import project1_child3 from "../../assets/projects/1/3.jpg";
import project1_child4 from "../../assets/projects/1/4.jpg";
import project2 from "../../assets/projects/2/main.jpg";
import project2_child1 from "../../assets/projects/2/1.jpg";
import project2_child2 from "../../assets/projects/2/2.jpg";
import project2_child3 from "../../assets/projects/2/3.jpg";
import project2_child4 from "../../assets/projects/2/4.jpg";
import project3 from "../../assets/projects/3/main.jpg";
import project3_child1 from "../../assets/projects/3/1.jpg";
import project3_child2 from "../../assets/projects/3/2.jpg";
import project3_child3 from "../../assets/projects/3/3.jpg";
import project3_child4 from "../../assets/projects/3/4.jpg";
import project4 from "../../assets/projects/4/main.jpg";
import project4_child1 from "../../assets/projects/4/1.jpg";
import project4_child2 from "../../assets/projects/4/2.jpg";
import project4_child3 from "../../assets/projects/4/3.jpg";
import project4_child4 from "../../assets/projects/4/4.jpg";
export const PORTFOLIO_LIST = [
  {
    id: 1,
    img: project1,
    alt: "Winter Park",
    title: "Winter Park",
    text: "Feb 2023",
    video: "project1.mp4",
    collection: [
      {
        id: 1,
        img: project1_child1,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 2,
        img: project1_child2,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 3,
        img: project1_child3,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
      {
        id: 4,
        img: project1_child4,
        alt: "Winter Park",
        title: "Winter Park",
        text: "Feb 2023",
      },
    ],
  },
  {
    id: 2,
    img: project2,
    alt: "Aurora",
    title: "Aurora",
    text: "Oct 2020",
    // video: "project2.mp4",
    collection: [
      {
        id: 1,
        img: project2_child1,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 2,
        img: project2_child2,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 3,
        img: project2_child3,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
      {
        id: 4,
        img: project2_child4,
        alt: "Aurora",
        title: "Aurora",
        text: "Oct 2020",
      },
    ],
  },
  {
    id: 3,
    img: project3,
    alt: "Denver",
    title: "Denver",
    text: "Jun 2021",
    video: "project3.mp4",
    collection: [
      {
        id: 1,
        img: project3_child1,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 2,
        img: project3_child2,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 3,
        img: project3_child3,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
      {
        id: 4,
        img: project3_child4,
        alt: "Denver",
        title: "Denver",
        text: "Jun 2021",
      },
    ],
  },
  {
    id: 4,
    img: project4,
    alt: "Genesee",
    title: "Genesee",
    text: "Aug 2021",
    collection: [
      {
        id: 1,
        img: project4_child1,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 2,
        img: project4_child2,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 3,
        img: project4_child3,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
      {
        id: 4,
        img: project4_child4,
        alt: "Genesee",
        title: "Genesee",
        text: "Aug 2021",
      },
    ],
  },
];
