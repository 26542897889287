import React from "react";

export const Shield = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 6.66675L13.3334 16.6667V36.9667C13.3334 53.8001 24.7 69.5001 40 73.3334C55.3 69.5001 66.6667 53.8001 66.6667 36.9667V16.6667L40 6.66675ZM36.4667 51.8001L24.6667 40.0001L29.3667 35.3001L36.4334 42.3667L50.5667 28.2334L55.2667 32.9334L36.4667 51.8001Z"
        fill="#FF4F00"
      />
    </svg>
  );
};
