import React from "react";
import ReactDOM from "react-dom/client";
import "./style/global.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDiBf-rDMzoPQMVZXRa-WE1X74EgoEL0wk",
  authDomain: "homecraft-masters.firebaseapp.com",
  projectId: "homecraft-masters",
  storageBucket: "homecraft-masters.appspot.com",
  messagingSenderId: "361713823111",
  appId: "1:361713823111:web:733f872c97f66dc5d97722",
  measurementId: "G-DTML1VPTZR",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App storage={storage} />
  </BrowserRouter>
);
